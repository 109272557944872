import React from 'react';

export const HTMLContent = ({ content, className }: ContentType) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
);

const Content = ({ content, className }: ContentType) => (
  <div className={className}>{content}</div>
);

export type ContentType = {
  content: any,
  className?: string,
};

export type ImageType = {
  childImageSharp: {
    fluid: any,
  },
};

export type ImageTypeExtended = {
  alt: string,
  image: ImageType,
};

export default Content;
