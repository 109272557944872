import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';
import Content, { HTMLContent, ContentType } from '../components/Content';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Img from 'gatsby-image';
import { ImageType } from '../components/Content';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

export const ProductPageTemplate = ({
  title,
  heading,
  description,
  products,
  content,
  contentComponent,
}: ProductPageTemplateType) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      <Container>
        <Row className="mb-3">
          <Col>
            <h1>{title}</h1>
            <h2>{heading}</h2>
            <p>{description}</p>
          </Col>
        </Row>
        <Row>
          {products && products.map((product, idx) => (
            <Col key={`product-${idx}`} className="mb-5">
              <PreviewCompatibleImage
                imageInfo={{
                  image: product.image,
                  alt: `Bild ${product.heading}`
                }}
              />
              <h3 className="mt-2">{product.heading}</h3>
              <p dangerouslySetInnerHTML={{ __html: product.text }}></p>
              {product.buttons && product.buttons.map((button, idx) => (
                <Link key={`button-text-${idx}`} to={`/${button.link}`} className={`btn mr-2 mt-2 ${button.style}`}>{button.text}</Link>
              ))}
            </Col>
          ))}
        </Row>
        <Row>
          <Col>
            <PageContent content={content} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

type ProductPageTemplateType = {
  title: string,
  heading: string,
  description: string,
  products: Array<{
    image: any | string,
    heading: string,
    text: string,
    buttons: [{
      text: string,
      link: string,
      style?: string,
    }],
  }>,
  content?: string,
  contentComponent?: { (data: ContentType): any },
};

const ProductPage = ({ data }: ProductPageType) => {
  const { frontmatter, html } = data.markdownRemark;

  return (
    <Layout>
      <ProductPageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        products={frontmatter.products}
        contentComponent={HTMLContent}
        content={html}
      />
    </Layout>
  );
};

type ProductPageType = {
  data: {
    markdownRemark: {
      html: string,
      frontmatter: {
        title: string,
        heading: string,
        description: string,
        products: Array<{
          image: ImageType,
          heading: string,
          text: string,
          buttons: [{
            text: string,
            link: string,
            style?: string,
          }],
        }>,
      },
    },
  },
};

export default ProductPage;

export const productPageQuery = graphql`
  query ProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heading
        description
        products {
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          heading
          text
          buttons {
            text
            link
            style
          }
        }
      }
    }
  }
`;
